<template>
  <div>
    <v-card
      flat
      class="pa-3 mt-2"
    >

      <v-card-text>
        <v-form ref="form" class="multi-col-validation mt-6">
          <v-row>
            <v-card
              flat
            >
              <v-toolbar dense dark color="primary">
                <v-toolbar-title><h4 class="font-weight-light">BEVERAGES AND SUPPLY</h4>
                </v-toolbar-title>
              </v-toolbar>
              <v-card-text>
                <v-row class="mt-4">
                  <v-col
                    cols="12"
                    md="12"
                  >
                    <v-select
                      v-model="type"
                      dense
                      outlined
                      label="Type"
                      :items="['Beverages','Supply']"
                      :rules="rules.combobox_rule"
                    ></v-select>
                  </v-col>
                  <v-col
                    md="12"
                    cols="12"
                  >
                    <v-text-field
                      v-model="last_name"
                      label="Name"
                      dense
                      outlined
                      :rules="rules.default_max_45_character_and_no_empty_rule"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    md="3"
                    cols="12"
                  >
                    <v-text-field
                      v-model="amount"
                      label="Amount"
                      dense
                      outlined
                      :rules="rules.default_max_45_character_and_no_empty_rule"
                      type="number"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
            <!-- alert -->
            <v-col cols="12" v-show="alert">
              <v-alert
                color="warning"
                text
                class="mb-0"

              >
                <div class="d-flex align-start">
                  <v-icon color="warning">
                    {{ icons.mdiAlertOutline }}
                  </v-icon>

                  <div class="ms-3">126009
                    <p class="text-base font-weight-medium mb-1">
                      {{alert_message}}
                    </p>
                  </div>
                </div>
              </v-alert>
            </v-col>

            <v-col cols="12" v-if="!for_update">
              <v-btn
                color="primary"
                class="me-3 mt-4"
                @click="save_employee"
                v-if="!saving"
              >
                Save changes
              </v-btn>
              <v-progress-circular
                :size=50
                :width="5"
                color="primary"
                indeterminate
                v-else
              ></v-progress-circular>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-data-table dense
                    :headers="headers"
                    :items="data_items"
                    hide-default-footer
                    disable-pagination
                    class="mt-3"
      >
        <template v-slot:item.action="{ item }">
          <tr>
            <td class="text-center">
              <div>
                <v-icon
                  v-if="!is_deleting"
                  class="mr-2"
                  color="info"
                  @click="is_deleting=true"
                >
                  {{icons.mdiPencil}}
                </v-icon>
                <v-progress-circular color="info" indeterminate
                                     v-if="is_deleting"></v-progress-circular>
              </div>
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-card>


    <snack-bar-dialog
      :snackbar_flag="this.snackbar"
      :color="this.snackbar_color"
      :snackbar_text="this.snackbar_text"
    />
  </div>
</template>

<script>
  import {mdiAlertOutline, mdiCloudUploadOutline, mdiPencil} from '@mdi/js'
  import 'croppie/croppie.css'// import the croppie css manually
  import {mapGetters, mapActions} from "vuex";
  import moment from 'moment'
  import snackBarDialog from '@/components/dialogs/notifications_dialog/Snackbar'

  const initialState = () => {
    return {
      show: false,
      avatarImg: require('@/assets/images/avatars/1.png'),

      saving: false,
      alert: false,
      alert_message: '',
      croppieImage: '',
      cropped: require('@/assets/images/avatars/1.png'),

      category_id: '',
      mode: '',
      category_items: [],
      branch_id: '',
      branch_items: [],
      employee_position_id: '',
      position_items: [],
      last_name: '',
      first_name: '',
      middle_name: '',
      date_of_birth: '',
      gender: '',
      civil_status: '',
      religion: '',
      date_of_employment: '',
      compensation_rate: 0,
      is_allowance_only: false,
      is_deleting: false,
      bank_type_1: '',
      bank_account_1: '',
      bank_account_no_1: '',
      bank_type_2: '',
      bank_account_2: '',
      bank_account_no_2: '',
      bank_type_3: '',
      bank_account_3: '',
      bank_account_no_3: '',
      sss_no: '',
      ph_no: '',
      pag_ibig_no: '',
      tin_no: '',
      emergency_name: '',
      emergency_address: '',
      emergency_contact_no: '',
      address: '',
      region_id: '',
      province_id: '',
      branch_city_id: '',
      region_items: [],
      province_items: [],
      branch_city_items: [],
      data_items: [],
      email_address: '',
      type: '',
      contact_no: '',
      amount_for_member: '',
      amount: '',
      headers: [
        {text: 'ID', value: 'id', sortable: false},
        {text: 'Type', value: 'type', sortable: false},
        {text: 'Name', value: 'name', sortable: false},
        {text: 'Amount', value: 'amount', sortable: false},
        {text: 'Action', value: 'action', sortable: false},
      ],
    }
  }

  export default {
    props: {
      for_update: Boolean,
      information_details: Object,
    },
    components: {
      snackBarDialog,
    },
    setup() {
      return {
        icons: {
          mdiAlertOutline,
          mdiCloudUploadOutline,
          mdiPencil,
        },
      }
    },

    data() {
      return initialState()
    },
    mounted() {
      this.initialize_data()
    },
    computed: {
      ...mapGetters('form_rules', ['rules']),
      ...mapGetters('system_data', ['snackbar', 'snackbar_color', 'snackbar_text', 'company_logo']),
      ...mapGetters('authentication', ['position']),
    },
    methods: {
      ...mapActions('system_data', ['change_snackbar']),
      ...mapActions('type_of_service', ['is_service_already_encoded', 'create_service', 'update_service_info', 'list_of_active_type_of_service']),
      initialize_data() {
        this.data_items = []
        this.list_of_active_type_of_service()
          .then(response => {
            this.data_items = response.data
          })
          .catch(error => {
            this.alert = true
            this.alert_message = error
            this.saving = false
          })
      },
      selected_region() {
        if (this.region_items.length > 0) {
          var index = this.region_items
            .map(function (x) {
              return x.id
            })
            .indexOf(this.region_id)
          if (index >= 0) {
            this.province_items = this.region_items[index].province
            if (!this.for_update) {
              this.province_id = ''
              this.branch_city_id = ''
            }
          }
        }
      },
      selected_province() {
        if (this.province_items.length > 0) {
          var index = this.province_items
            .map(function (x) {
              return x.id
            })
            .indexOf(this.province_id)
          if (index >= 0) {
            this.branch_city_items = this.province_items[index].city
            if (!this.for_update) {
              this.branch_city_id = ''
            }
          }
        }
      },
      croppie(e) {
        this.show = true;
        var files = e.target.files || e.dataTransfer.files;
        if (!files.length) return;

        var reader = new FileReader();
        reader.onload = e => {
          this.cropped = this.croppieImage = e.target.result
        };
        reader.readAsDataURL(files[0]);
      },
      resett() {
        this.$refs.refInputEl.value = ''
        this.cropped = this.avatarImg
      },
      reset() {
        Object.assign(this.$data, initialState())
      },
      save_employee() {
        this.saving = true
        this.alert = false
        if (this.$refs.form.validate()) {
          var proceed = true;
          if (proceed) {
            const data = new FormData()
            data.append('Type', this.type);
            data.append('Name', this.last_name.toUpperCase());
            data.append('amount', this.amount);
            this.is_service_already_encoded(data)
              .then(response => {
                if (response.data === 1) {
                  this.alert = true
                  this.alert_message = 'Service Already Encoded in this Category!'
                  this.saving = false
                } else {
                  this.create_service(data)
                    .then(response => {
                      this.change_snackbar({
                        show: true,
                        color: 'success',
                        text: response.data,
                      })
                      this.reset()
                    })
                    .catch(error => {
                      this.alert = true
                      this.alert_message = error
                      this.saving = false
                    })
                }
              })
              .catch(error => {
                this.alert = true
                this.alert_message = error
                this.saving = false
              })
          }
        } else {
          this.alert = true
          this.alert_message = 'Please Fill up the field/s'
          this.saving = false
        }
      },
    }
  }
</script>
